import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import { Row, Col } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import Content from "../components/Content"
import Image from "../components/Image"


const Post = ({ pageContext }) => {
  const data = useStaticQuery(graphql `
    query LatestPostsQuery {
      allStrapiWilma19Post(sort: {order: DESC, fields: date}, limit: 5) {
        edges {
          node {
            slug
            title
            date(formatString: "DD.MM.YYYY", locale: "de")
          }
        }
      }
    }
  `)

  const BigHeader = () => (
    <div className="mb-3">
      <Image image={pageContext.bigHeaderImage} />
    </div>
  )

  const LatestPosts = () => (
    <>
      {data.allStrapiWilma19Post.edges.map(post => (
        <div className="mb-3">
          <Link to={'/' + post.node.slug} key={post.node.slug}>{post.node.date + ' - ' + post.node.title}</Link>
        </div>
      ))}
    </>
  )

  return <Layout sideNavExtra={<LatestPosts />}>
    <Fade>
      {pageContext.bigHeaderImage ? <BigHeader /> : <></>}

      <Row>
        <Col md={8} className="pe-md-5">
          <span>{pageContext.date}</span>
          <h1 className="mb-5">{pageContext.title}</h1>
          <Content>{pageContext.content.data.content}</Content>
        </Col>
        <Col md={4} className="ps-md-3">
          {pageContext.images?.length === 0 && !pageContext.bigHeaderImage ?
            <Image image={pageContext.featuredImage} /> : null}

          {pageContext.images?.map(image => <Image image={image} />)}
        </Col>
      </Row>
    </Fade>
  </Layout>
}

export default Post
